import { createTheme } from '@mui/material/styles';
import {outlinedInputStyles} from "./components/outlined-input.styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#FF6500',
        },
        secondary: {
            main: '#2fa4f3'
        }
    },
    components: {
        MuiOutlinedInput: outlinedInputStyles,
    },
});

