import React, { useState } from "react";
import {
  Typography,
  AppBar,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import { Company } from "../types";
import { useThemeContext } from "../context/theme";
import { ThemeType } from "../types";
import { Logo } from "./Logo";

export const Header = () => {
  const [company, setCompany] = useState("Iteo");
  const { theme, setTheme } = useThemeContext();

  const handleChange = (event: SelectChangeEvent) => {
    switch (event.target.value) {
      case Company.iteo:
        setTheme("primary");
        break;
      case Company.iteoVoice:
        setTheme("secondary");
        break;
    }
    setCompany(event.target.value);
  };
  return (
    <AppBar
      position={"static"}
      elevation={4}
      color={theme as ThemeType}
      sx={{ padding: "10px" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Box sx={{width: '30px', marginLeft: '20px'}}>
          <Logo />
        </Box>
        <Typography variant={"h6"} sx={{ color: "#fff" }}>
          Iteo Signature
        </Typography>
        <Select value={company} onChange={handleChange}>
          <MenuItem value={Company.iteo}>{Company.iteo}</MenuItem>
          <MenuItem value={Company.iteoVoice}>{Company.iteoVoice}</MenuItem>
        </Select>
      </Box>
    </AppBar>
  );
};
