import React, { useRef } from "react";
import { Button, Typography, Box } from "@mui/material";
import { useThemeContext } from "../../context/theme";
import { ThemeType } from "../../types";
import { MainTable } from "./components";
import { useAppContext } from "../../context";
import { selectContents } from "../../utils/selectContents";

export const Signature = () => {
  const { theme } = useThemeContext();
  const { user } = useAppContext();
  const tableRef = useRef(null);

  const propsTable = { user, theme };

  return (
    <Box>
      <Button
        variant={"contained"}
        color={theme as ThemeType}
        sx={{ marginBottom: "20px", color: "#fff" }}
        onClick={() => selectContents(tableRef.current)}
      >
        Kopiuj do Gmail
      </Button>
      <Typography variant={"h6"} sx={{ paddingTop: "20px" }}>
        Podgląd
      </Typography>
      <MainTable tableRef={tableRef} {...propsTable} />
    </Box>
  );
};
