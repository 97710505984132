import React from "react";
import { TextField, Grid } from "@mui/material";
import { useAppContext } from "../context";
import { useThemeContext } from "../context/theme";
import { ThemeType } from "../types";

export const Form = () => {
  const { setUser, user } = useAppContext();
  const { theme } = useThemeContext();
  return (
    <Grid
      container
      sx={{ paddingBottom: "20px", width: "unset" }}
      direction={"column"}
      spacing={"15px"}
    >
      <Grid item>
        <TextField
          value={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          label={"Imię"}
          color={theme as ThemeType}
          variant={"standard"}
          sx={{ width: "135px", marginRight: "15px" }}
        />
        <TextField
          value={user.lastName}
          onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          label={"Nazwisko"}
          color={theme as ThemeType}
          variant={"standard"}
          sx={{ width: "150px" }}
        />
      </Grid>
      <Grid item>
        <TextField
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          label={"Email"}
          color={theme as ThemeType}
          variant={"standard"}
          sx={{ width: "300px" }}
        />
      </Grid>
      <Grid item>
        <TextField
          value={user.position}
          onChange={(e) => setUser({ ...user, position: e.target.value })}
          label={"Stanowisko"}
          color={theme as ThemeType}
          variant={"standard"}
          sx={{ width: "300px" }}
        />
      </Grid>
      <Grid item>
        <TextField
          value={user.number}
          onChange={(e) => setUser({ ...user, number: e.target.value })}
          label={"Numer telefonu"}
          color={theme as ThemeType}
          variant={"standard"}
          sx={{ width: "300px" }}
        />
      </Grid>
    </Grid>
  );
};
