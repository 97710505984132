import React from "react";
import { Header } from "./components/Header";
import { Form } from "./components/Form";
import { Signature } from "./modules/signature";
import { Box } from "@mui/material";
import { ContextProvider } from "./context";
import { ThemeContextProvider } from "./context/theme";
import { HtmlSignature } from "./components/HtmlSignature";
import { MarkdownCreator } from "./components/MarkdownCreator";

function App() {
  return (
    <ContextProvider>
      <ThemeContextProvider>
        <Header />
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", gap: "100px" }}>
            <Box>
              <Form />
              <MarkdownCreator />
            </Box>
            <Signature />
          </Box>
          <HtmlSignature />
        </Box>
      </ThemeContextProvider>
    </ContextProvider>
  );
}

export default App;
