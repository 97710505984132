import React, { FC } from "react";
import { NameTable } from "./NameTable";
import { InfoTable } from "./InfoTable";
import { ThemeType } from "../../../types";
import { CompanyInfo } from "./CompanyInfo";
import { UserModel } from "../../../context";

interface Props {
  tableRef?: any;
  user: UserModel;
  theme: ThemeType;
}

export const MainTable: FC<Props> = ({ tableRef, user }) => {
  return (
    <table cellPadding="0" cellSpacing="0" ref={tableRef} id={"table"}>
      <tbody>
        <tr>
          <td>
            <NameTable
              name={user.name}
              lastName={user.lastName}
              position={user.position}
            />
          </td>
        </tr>
        <tr>
          <td>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{ padding: "0" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0.28px",
                        margin: "0",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <a
                        href={`mailto:${user.email}`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {user.email}
                      </a>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <InfoTable email={user.email} number={user.number} />
          </td>
        </tr>
        <tr>
          <td>
            <hr style={{ opacity: "0.4", margin: "10px 0" }} />
          </td>
        </tr>
        <tr>
          <td>
            <CompanyInfo />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
