import React, {
  createContext,
  useState,
  FC,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface Props {
  children: ReactNode;
}

export interface UserModel {
  name: string;
  lastName: string;
  email: string;
  position: string;
  number: string;
}

interface ContextProps {
  setUser: Dispatch<SetStateAction<UserModel>>;
  user: UserModel;
  markdownData: string;
  setData: Dispatch<SetStateAction<string>>;
}

const AppContext = createContext<ContextProps>({
  markdownData: "",
  setData(value: ((prevState: string) => string) | string): void {},
  setUser(value: ((prevState: UserModel) => UserModel) | UserModel): void {},
  user: {
    number: "",
    name: "Jan",
    lastName: "Kowalski",
    email: "example@iteo.com",
    position: "Developer",
  },
});

export const ContextProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<UserModel>({
    number: "",
    name: "Jan",
    lastName: "Kowalski",
    email: "example@iteo.com",
    position: "Developer",
  });

  const [markdownData, setData] = useState<string>("");

  return (
    <AppContext.Provider
      value={{
        setUser,
        user,
        markdownData,
        setData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
