import { OutlinedInputProps, OutlinedInputClasses } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const outlinedInputStyles: {
  defaultProps?: Partial<OutlinedInputProps> | undefined;
  styleOverrides?:
    | Partial<OverridesStyleRules<keyof OutlinedInputClasses>>
    | undefined;
} = {
  styleOverrides: {
    root: {
      "& .MuiInputBase-input": {
        color: "#fff",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fff !important",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
};
