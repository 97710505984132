import React from "react";
import { renderToString } from "react-dom/server";
import { useAppContext } from "../context";
import { Button } from "@mui/material";
import { useThemeContext } from "../context/theme";
import { ThemeType } from "../types";
import { MainTable } from "../modules/signature/components";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const HtmlSignature = () => {
  const { user } = useAppContext();
  const { theme } = useThemeContext();

  const propsTable = { user, theme };

  return (
    <>
      <CopyToClipboard text={renderToString(<MainTable {...propsTable} />)} >
        <Button
          variant={"contained"}
          color={theme as ThemeType}
          sx={{ marginTop: "20px", color: "#fff" }}
        >
          Skopiuj do schowka
        </Button>
      </CopyToClipboard>

      <textarea
        style={{
          width: "100%",
          minWidth: "100%",
          maxWidth: "100%",
          maxHeight: "40vh",
          minHeight: "30vh",
        }}
        readOnly
        value={renderToString(<MainTable {...propsTable} />)}
      />
    </>
  );
};
