import React from "react";
import { TextField, Typography } from "@mui/material";
import { ThemeType } from "../types";
import { useThemeContext } from "../context/theme";
import { useAppContext } from "../context";
import { StyledMarkdown } from "../modules/signature/components/CompanyInfo";

export const MarkdownCreator = () => {
  const { theme } = useThemeContext();
  const { setData } = useAppContext();
  return (
    <>
      <Typography style={{fontWeight: "bold"}}>Treść nieobowiązkowa</Typography>
      <Typography>Przykład:</Typography>
      <Typography sx={{ maxWidth: "300px", margin: "10px 0" }}>
        Want to know how to overcome cognitive bias while designing a product?
        [Sign up](https://iteo.com/) for our webinar planned for July 13th.
      </Typography>
      <Typography>Wynik:</Typography>
      <Typography sx={{ maxWidth: "300px", margin: "10px 0" }}>
        <StyledMarkdown>
          Want to know how to overcome cognitive bias while designing a product?
          [Sign up](https://iteo.com/) for our webinar planned for July 13th.
        </StyledMarkdown>
      </Typography>
      <TextField
        color={theme as ThemeType}
        variant={"standard"}
        sx={{ width: "100%" }}
        label={"Markdown tekst"}
        multiline
        onChange={(e) => setData(e.target.value)}
      />
    </>
  );
};
