import React, {
  createContext,
  useState,
  FC,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { ThemeType } from "../types";


interface Props {
  children: ReactNode;
}

interface ContextProps {
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>>;
}

const ThemeContext = createContext<ContextProps>({
  setTheme(): void {},
  theme: "primary",
});

export const ThemeContextProvider: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeType>("primary");

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
