import { theme as muiTheme } from "../theme";
import {ThemeType} from '../types';

type Template = {
  [key in ThemeType]: string;
};
export const pickColor = (theme: ThemeType) => {
  const template: Template = {
    primary: muiTheme.palette.primary.main,
    secondary: muiTheme.palette.secondary.main
  }
  return template[theme] || template.primary
};
export const pickLogo = (theme: ThemeType) => {
  const template: Template = {
    primary: 'https://assets.iteo.com/footer/logo.png',
    secondary: 'https://iteo.com/assets/iteo-voice.png'
  }
  return template[theme] || template.primary
};




