import React from "react";

export const SocialMediaTable = () => {
  return (
    <table cellPadding="0" cellSpacing="0">
      <tbody>
        <tr>
          <td>
            <p style={{ margin: "5px 10px 0" }}>
              <a href="https://www.facebook.com/iteo.apps" target="_blank">
                <img
                  src="https://iteo.com/assets/facebook.png"
                  alt=""
                  width={14}
                  height={14}
                />
              </a>
            </p>
          </td>
          <td>
            <p style={{ margin: "5px 10px 0" }}>
              <a href="https://www.instagram.com/iteo_apps/" target="_blank">
                <img
                  src="https://iteo.com/assets/instagram.png"
                  alt=""
                  width={14}
                  height={14}
                />
              </a>
            </p>
          </td>
          <td>
            <p style={{ margin: "5px 10px 0" }}>
              <a
                href="https://www.linkedin.com/company/iteo.-design-and-development-agency/"
                target="_blank"
              >
                <img
                  src="https://iteo.com/assets/linkedin.png"
                  alt=""
                  width={14}
                  height={14}
                />
              </a>
            </p>
          </td>
          <td>
            <p style={{ margin: "5px 10px 0" }}>
              <a href="https://twitter.com/iteo_apps" target="_blank">
                <img
                  src="https://iteo.com/assets/twitter.png"
                  alt=""
                  width={14}
                  height={14}
                />
              </a>
            </p>
          </td>
          <td>
            <p style={{ margin: "5px 10px 0" }}>
              <a href="https://dribbble.com/iteo" target="_blank">
                <img
                  src="https://iteo.com/assets/dribble.png"
                  alt=""
                  width={14}
                  height={14}
                />
              </a>
            </p>
          </td>
          <td>
            <p style={{ margin: "5px 10px 0" }}>
              <a href="https://www.behance.net/iteo_apps" target="_blank">
                <img
                  src="https://iteo.com/assets/behance.png"
                  alt=""
                  width={14}
                  height={14}
                />
              </a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
